.mainContainer {
  display: flex;
  justify-content: center;
  align-items: center;
  height: 100vh;
  background: url(../../assets/img/andrea-cau-nV7GJmSq3zc-unsplash.jpg)
    no-repeat center center/cover;
}

.innerContainer {
  max-width: 70%;
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  padding: 3rem 4rem;
  border: 1px solid var(--gray);
  border-radius: 10px;
  box-shadow: 5px 4px 8px 4px #0000004d;
  background: rgba(255, 255, 255, 0.89);
}

.innerContainer > p {
  font-size: 1.2rem;
  margin-bottom: 0.8rem;
}

.data {
  font-size: 1rem;
}

.countryName {
  color: var(--blue);
  font-size: 1.5rem;
  font-weight: bold;
}

span.death {
  color: var(--red);
  font-size: 1.5rem;
  font-weight: bold;
}

span.case {
  color: var(--green);
  font-size: 1.5rem;
  font-weight: bold;
}

@media (max-width: 950px) {
  span.death,
  span.case,
  .countryName {
    font-size: 1.2rem;
  }

  .innerContainer > p {
    font-size: 1rem;
    margin-bottom: 0.8rem;
  }

  .innerContainer {
    display: flex;
    flex-direction: column;
    justify-content: flex-start;
    align-items: flex-start;
  }
}

@media (max-width: 600px) {
  .innerContainer {
    padding: 2rem;
  }
}
