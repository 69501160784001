@import url("https://fonts.googleapis.com/css2?family=Dosis:wght@300;400;500;600&family=Pangolin&display=swap");

:root {
  --red: #d32f2fc2;
  --yellow: #fbc02dc2;
  --orange: #f57c00c2;
  --purple: #512da8c2;
  --green: #388e3cc2;
  --gray: #616161c2;
  --blue: #0288d1;
  --regularFont: "Dosis", sans-serif;
  --headingFont: "Pangolin", cursive;
}

html {
  height: 100%;
}

p,
ul,
span {
  margin: 0;
  padding: 0;
}

body {
  margin: 0;
  min-height: 100%;
  position: relative;
  font-family: var(--regularFont);
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
}

code {
  font-family: source-code-pro, Menlo, Monaco, Consolas, "Courier New",
    monospace;
}
