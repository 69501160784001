.card {
  width: 70%;
  padding: 1.5rem;
  margin: 0.5rem;
  border-radius: 30px;
  display: flex;
  flex-direction: column;
  justify-content: flex-end;
  box-shadow: 2px 2px 4px #525252;
  background-color: #fffffffa;
}

.card__journal {
  font-size: 0.8rem;
  font-style: italic;
  padding-left: 0.2rem;
}

.card__header {
  font-weight: bold;
  padding: 0.25rem 0.25rem;
  margin: 0.25rem 0;
  border-radius: 10px;
}

.card__header:hover {
  background: #ffd100;
}

.card__pubdate {
  font-weight: bold;
}

.card__link {
  text-decoration: none;
  color: #202020;
  font-size: 1.15rem;
}

.card__authors {
  font-size: 0.8rem;
  padding-left: 0.2rem;
}

@media (max-width: 600px) {
  .card__link {
    font-size: 0.9rem;
  }

  .card__journal,
  .card__authors {
    font-size: 0.65rem;
  }
}
