@import url(https://fonts.googleapis.com/css2?family=Dosis:wght@300;400;500;600&family=Pangolin&display=swap);
:root {
  --red: #d32f2fc2;
  --yellow: #fbc02dc2;
  --orange: #f57c00c2;
  --purple: #512da8c2;
  --green: #388e3cc2;
  --gray: #616161c2;
  --blue: #0288d1;
  --regularFont: "Dosis", sans-serif;
  --headingFont: "Pangolin", cursive;
}

html {
  height: 100%;
}

p,
ul,
span {
  margin: 0;
  padding: 0;
}

body {
  margin: 0;
  min-height: 100%;
  position: relative;
  font-family: "Dosis", sans-serif;
  font-family: var(--regularFont);
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
}

code {
  font-family: source-code-pro, Menlo, Monaco, Consolas, "Courier New",
    monospace;
}

.App-logo {
  height: 40vmin;
  pointer-events: none;
}

@media (prefers-reduced-motion: no-preference) {
  .App-logo {
    animation: App-logo-spin infinite 20s linear;
  }
}

.App-header {
  background-color: #282c34;
  min-height: 100vh;
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  font-size: calc(10px + 2vmin);
  color: white;
}

.App-link {
  color: #61dafb;
}

@keyframes App-logo-spin {
  from {
    transform: rotate(0deg);
  }

  to {
    transform: rotate(360deg);
  }
}
.article_card__39_S7 {
  width: 70%;
  padding: 1.5rem;
  margin: 0.5rem;
  border-radius: 30px;
  display: flex;
  flex-direction: column;
  justify-content: flex-end;
  box-shadow: 2px 2px 4px #525252;
  background-color: #fffffffa;
}

.article_card__journal__2QG5s {
  font-size: 0.8rem;
  font-style: italic;
  padding-left: 0.2rem;
}

.article_card__header__3238J {
  font-weight: bold;
  padding: 0.25rem 0.25rem;
  margin: 0.25rem 0;
  border-radius: 10px;
}

.article_card__header__3238J:hover {
  background: #ffd100;
}

.article_card__pubdate__3IfDs {
  font-weight: bold;
}

.article_card__link__2EAGY {
  text-decoration: none;
  color: #202020;
  font-size: 1.15rem;
}

.article_card__authors__gBHib {
  font-size: 0.8rem;
  padding-left: 0.2rem;
}

@media (max-width: 600px) {
  .article_card__link__2EAGY {
    font-size: 0.9rem;
  }

  .article_card__journal__2QG5s,
  .article_card__authors__gBHib {
    font-size: 0.65rem;
  }
}

.articles_article__main__container__YGVIx {
  display: flex;
  flex-direction: column;
  padding: 3.4rem 0;
  width: 100vw;
}

.articles_article__container__3U9nW {
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  padding-top: 1rem;
  background: url(/static/media/pawel-czerwinski-WteXlFwm0jY-unsplash.ef6ac26b.jpg)
    no-repeat center center fixed;
  /* background-size: cover; */
}

.articles_article__main__header__1kT0v {
  max-width: 70%;
  padding: 1rem;
  font-family: var(--headingFont);
  font-size: 2rem;
  align-self: center;
}

@media (max-width: 600px) {
  .articles_article__main__header__1kT0v {
    font-size: 1.25rem;
  }
}

.footer_footer__1lZIc {
  width: 100%;
  height: 3.3rem;
  display: flex;
  justify-content: center;
  align-items: center;
  position: absolute;
  left: 0;
  right: 0;
  bottom: 0;
  border-top: 2px solid #202020;
  background-color: white;
}

.footer_footer__link__16Kx5 {
  text-decoration: none;
  font-weight: bold;
  color: var(--purple);
}

@media(max-width: 900px) {

  .footer_footer__link__16Kx5,
  .footer_footer__text__g-OYd {
    font-size: .8rem
  }
}
.landing_mainContainer__2oU8n {
  display: flex;
  justify-content: center;
  align-items: center;
  height: 100vh;
  background: url(/static/media/andrea-cau-nV7GJmSq3zc-unsplash.a6a21278.jpg)
    no-repeat center center/cover;
}

.landing_innerContainer__24e8r {
  max-width: 70%;
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  padding: 3rem 4rem;
  border: 1px solid var(--gray);
  border-radius: 10px;
  box-shadow: 5px 4px 8px 4px #0000004d;
  background: rgba(255, 255, 255, 0.89);
}

.landing_innerContainer__24e8r > p {
  font-size: 1.2rem;
  margin-bottom: 0.8rem;
}

.landing_data__2O7pu {
  font-size: 1rem;
}

.landing_countryName__iwKSz {
  color: var(--blue);
  font-size: 1.5rem;
  font-weight: bold;
}

span.landing_death__1NB7M {
  color: var(--red);
  font-size: 1.5rem;
  font-weight: bold;
}

span.landing_case__dNSHt {
  color: var(--green);
  font-size: 1.5rem;
  font-weight: bold;
}

@media (max-width: 950px) {
  span.landing_death__1NB7M,
  span.landing_case__dNSHt,
  .landing_countryName__iwKSz {
    font-size: 1.2rem;
  }

  .landing_innerContainer__24e8r > p {
    font-size: 1rem;
    margin-bottom: 0.8rem;
  }

  .landing_innerContainer__24e8r {
    display: flex;
    flex-direction: column;
    justify-content: flex-start;
    align-items: flex-start;
  }
}

@media (max-width: 600px) {
  .landing_innerContainer__24e8r {
    padding: 2rem;
  }
}

.burgerMenu_menu__icon__2wdjK {
  font-size: 1.2rem;
  padding: 1.15rem 0.5rem;
}

.burgerMenu_menu__icon__2wdjK:hover {
  cursor: pointer
}

.burgerMenu_navbar__side__x__2qrN_ {
  display: flex;
  justify-content: flex-end;
}

.burgerMenu_navbar__side__container__3PIVe {
  width: 15rem;
  height: 100vh;
  background-color: #cfcfcf;
  z-index: 30;
  position: absolute;
  top: 0;
  right: 0;
  bottom: 0
}

.burgerMenu_backdrop__Adrsz {
  width: 100%;
  height: 100%;
  z-index: 29;
  background-color: var(--gray);
  position: fixed;
  top: 0;
  right: 0;
  bottom: 0;
  left: 0;
}

.burgerMenu_navbar__container__1SKRE {
  display: flex;
  flex-direction: column;
}

.burgerMenu_navbar__item__2VO2O {
  text-decoration: none;
  padding: 1rem 2rem;
  color: #202020;
  font-size: 1.2rem;
}
.navbar_invisible__1D9Zy {
  display: none;
}

.navbar_visible__3AKFk {
  display: inline-block;
}

.navbar_navbar__q1H5d {
  display: flex;
  flex-direction: row;
  justify-content: space-between;
  border-bottom: 2px solid #202020;
  height: 3.3rem;
  position: fixed;
  left: 0;
  right: 0;
  top: 0;
  z-index: 10;
  background-color: white;
}

.navbar_navbar__container__31Zlf {
  display: flex;
  flex-direction: row;
  padding-right: 1rem;
  position: relative;
}

.navbar_navbar__item__1M4-8 {
  padding: 1rem;
  text-decoration: none;
  color: #303030;
}

.navbar_navbar__header__180Dy {
  display: flex;
  justify-content: center;
  align-items: center;
  padding: 0 1rem;
  font-size: 1.6rem;
  font-weight: bold;
}

.navbar_navbar__header__link__7mBsb {
  text-decoration: none;
  color: #202020;
}

@media (max-width: 850px) {
  .navbar_navbar__header__180Dy {
    font-size: 1.2rem;
  }

  .navbar_navbar__item__1M4-8 {
    font-size: 0.8rem;
    padding: 1.15rem 0.5rem;
  }
}

@media (max-width: 600px) {
  .navbar_navbar__header__180Dy {
    font-size: 1.1rem;
    padding: 0 0.5rem;
  }

  .navbar_navbar__item__1M4-8 {
    font-size: 0.75rem;
    font-weight: bold;
    padding: 1.15rem 0.5rem;
  }
}

@media (max-width: 250px) {
  .navbar_navbar__header__180Dy {
    font-size: 0.9rem;
  }
}

.countryMarker_marker__jHt4o {
  border-radius: 50%;
  display: flex;
  justify-content: center;
  align-items: center;
  font-weight: bold;
  font-size: 0.8rem;
}

.countryMarker_inverted__1FXjx {
  background-color: var(--blue) !important;
}

.countryMarker_red__94eiR {
  background-color: var(--red);
  border: 1px solid white;
  color: white;
}

.countryMarker_gray__ohlS3 {
  background-color: var(--gray);
  border: 1px solid white;
  color: white;
}

.countryMarker_green__3aXky {
  background-color: var(--green);
  border: 1px solid white;
  color: white;
}

.countryMarker_yellow__1-hQ8 {
  background-color: var(--yellow);
  border: 1px solid white;
  color: white;
}

.countryMarker_orange__2oWv2 {
  background-color: var(--orange);
  border: 1px solid white;
  color: white;
}

.countryMarker_purple__P2KCw {
  background-color: var(--purple);
  border: 1px solid white;
  color: white;
}

.infoBox_info__container__1OgbG {
  width: 12rem;
  height: 12rem;
  z-index: 20;
  background: white;
  color: #202020;
  border-radius: 1rem;
  display: flex;
  justify-content: center;
  align-items: center;
}

.infoBox_info__container__heading__34qWb {
  font-weight: bold;
  font-size: 1rem;
  margin-bottom: 0.4rem;
}

.infoBox_info__container__1OgbG > ul {
  list-style: none;
}

.infoBox_info__container__ul__1YllQ > li {
  display: flex;
  justify-content: flex-start;
  align-items: flex-start;
}

.infoBox_info__container__title__12WeC {
  font-weight: bold;
  padding-bottom: 0.1rem;
}

.worldStatus_mainWrapper__1f0-U {
  padding: 3.5rem 0;
  width: 100vw;
}

.worldStatus_table__2mEVo {
  width: 90%;
  max-width: 1400px;
  margin: auto;
  border: none;
  border-collapse: collapse;
  text-align: right;
}

.worldStatus_table__inner__container__1cUaz {
  display: flex;
}

.worldStatus_table__info__36JBS {
  width: 80%;
  padding: 0.5rem 0;
  background-color: #ffd100;
  border-bottom-left-radius: 20px;
  border-bottom-right-radius: 20px;
  font-weight: bold;
  text-align: center;
}

.worldStatus_table__2mEVo th {
  padding: 0.4rem 1.5rem;
  text-align: right;
  background-color: #eeeeee;
  cursor: pointer;
  white-space: nowrap;
}

.worldStatus_table__2mEVo td {
  padding: 0.3rem 1.5rem;
  white-space: nowrap;
}
.worldStatus_table__2mEVo td:first-child {
  font-weight: bold;
}
.worldStatus_table__2mEVo tr:nth-child(2n) {
  background-color: #f5f5f5;
}
.worldStatus_table__2mEVo tbody tr:hover {
  background-color: #464646;
  color: white;
}

.worldStatus_table__container__2jxA2 {
  display: flex;
  flex-direction: column;
  width: 100%;
  align-items: center;
}

.worldStatus_active_case__TIkWN {
  background-color: var(--red) !important;
  color: white;
}

.worldStatus_active_death__tnxNU {
  background-color: var(--gray) !important;
  color: white;
}

.worldStatus_active_deathsPerMillion__20WUg {
  background-color: var(--orange) !important;
  color: white;
}

.worldStatus_active_casesPerMillion__j7Xyy {
  background-color: var(--purple) !important;
  color: white;
}

.worldStatus_field__select__2lWy8 {
  display: none;
}

@media (max-width: 1400px) and (min-width: 1130px) {
  .worldStatus_table__2mEVo {
    width: 70%;
    max-width: 1000px;
  }

  .worldStatus_table__2mEVo th {
    padding: 0.2rem 0.6rem;
    font-size: 1rem;
  }

  .worldStatus_table__2mEVo td {
    padding: 0.2rem 0.6rem;
    font-size: 1rem;
  }
}
@media (max-width: 1130px) and (min-width: 950px) {
  .worldStatus_table__2mEVo {
    width: 70%;
    max-width: 700px;
  }

  .worldStatus_table__2mEVo th {
    padding: 0.9rem 0.3rem;
    font-size: 0.8rem;
  }

  .worldStatus_table__2mEVo td {
    padding: 0.1rem 0.3rem;
    font-size: 0.8rem;
  }

  .worldStatus_table__header__2ezlQ {
    margin-top: 1rem;
    height: 15vh;
    width: auto;
  }
}
@media (max-width: 950px) and (min-width: 500px) {
  .worldStatus_table__2mEVo {
    width: 90%;
    max-width: 700px;
  }

  .worldStatus_table__2mEVo th {
    padding: 0.6rem 0.3rem;
    font-size: 0.8rem;
  }

  .worldStatus_table__2mEVo td {
    padding: 0.1rem 0.3rem;
    font-size: 0.8rem;
  }

  .worldStatus_table__header__2ezlQ {
    margin-top: 1rem;
    height: 10vh;
    width: auto;
  }

  .worldStatus_table__info__36JBS {
    padding: 0.8rem 0;
    font-size: 0.8rem;
  }
}
@media (max-width: 800px) {
  .worldStatus_table__2mEVo th {
    display: none;
  }

  .worldStatus_table__2mEVo td {
    display: none;
  }

  .worldStatus_field__select__2lWy8 {
    display: block;
    width: 50%;
    border-radius: 10px;
    outline: none;
  }

  table .worldStatus_country__2kflp {
    display: table-cell;
  }

  table th.worldStatus_active_case__TIkWN {
    display: table-cell;
  }
  table td.worldStatus_active_row__2IYpr {
    display: table-cell;
  }

  table th.worldStatus_active_death__tnxNU {
    display: table-cell;
  }
  table th.worldStatus_active_casesPerMillion__j7Xyy {
    display: table-cell;
  }
  table th.worldStatus_active_deathsPerMillion__20WUg {
    display: table-cell;
  }
}
@media (max-width: 500px) {
  .worldStatus_table__2mEVo {
    width: 90%;
    max-width: 450px;
  }

  .worldStatus_table__2mEVo th {
    padding: 0.4rem 0.2rem;
    font-size: 0.7rem;
  }

  .worldStatus_table__2mEVo td {
    padding: 0.1rem 0.3rem;
    font-size: 0.7rem;
  }

  .worldStatus_table__info__36JBS {
    padding: 0.6rem 0;
    font-size: 0.7rem;
  }
}

.stateInfoBox_info__container__2O2Dh {
  width: 11rem;
  height: 9rem;
  z-index: 20;
  background: white;
  color: #202020;
  border-radius: 1rem;
  display: flex;
  justify-content: center;
  align-items: center;
}

.stateInfoBox_info__container__heading__2HYGI {
  font-weight: bold;
  font-size: 1rem;
  margin-bottom: 0.1rem;
}

.stateInfoBox_info__container__2O2Dh > ul {
  list-style: none;
}

.stateInfoBox_info__container__ul__2NqBn > li {
  display: flex;
  justify-content: flex-start;
  align-items: flex-start;
}

.stateInfoBox_info__container__title__2Wpht {
  font-weight: bold;
  padding-bottom: 0.1rem;
}

.stateMarker_marker__1X3_r {
  border-radius: 50%;
  display: flex;
  justify-content: center;
  align-items: center;
  font-weight: bold;
  font-size: 0.8rem;
}

.stateMarker_inverted__32TJJ {
  background-color: var(--blue) !important;
}

.stateMarker_small__ntfNe {
  height: 20px;
  width: 20px;
  margin-left: -10px;
  margin-top: -10px;
}

.stateMarker_medium__3d7Bx {
  height: 30px;
  width: 30px;
  margin-left: -15px;
  margin-top: -15px;
}

.stateMarker_large__1ju-O {
  height: 40px;
  width: 40px;
  margin-left: -20px;
  margin-top: -20px;
}

.stateMarker_x_large__2zidw {
  height: 50px;
  width: 50px;
  margin-left: -25px;
  margin-top: -25px;
}

.stateMarker_xx_large__2oYo9 {
  height: 60px;
  width: 60px;
  margin-left: -30px;
  margin-top: -30px;
}

.stateMarker_xxx_large__1VjtV {
  height: 66px;
  width: 66px;
  margin-left: -33px;
  margin-top: -33px;
}

.stateMarker_red__hH4Tg {
  background-color: var(--red);
  border: 1px solid white;
  color: white;
}

.stateMarker_gray__2Eyll {
  background-color: var(--gray);
  border: 1px solid white;
  color: white;
}

.stateMarker_green__YxiMM {
  background-color: var(--green);
  border: 1px solid white;
  color: white;
}

.stateMarker_yellow__1QNSh {
  background-color: var(--yellow);
  border: 1px solid white;
  color: white;
}

.stateMarker_orange__39GBV {
  background-color: var(--orange);
  border: 1px solid white;
  color: white;
}

.stateMarker_purple__3xtZQ {
  background-color: var(--purple);
  border: 1px solid white;
  color: white;
}

.usStatus_mainWrapper__3-52v {
  padding: 3.5rem 0;
  width: 100vw;
}

.usStatus_table__3hxrU {
  width: 90%;
  max-width: 1400px;
  margin: auto;
  border: none;
  border-collapse: collapse;
  text-align: right;
}

.usStatus_table__inner__container__LmGAt {
  display: flex;
}

.usStatus_table__info__3FZRT {
  width: 80%;
  padding: 0.5rem 0;
  background-color: #ffd100;
  border-bottom-left-radius: 20px;
  border-bottom-right-radius: 20px;
  font-weight: bold;
  text-align: center;
}

.usStatus_table__3hxrU th {
  padding: 0.4rem 1.5rem;
  text-align: right;
  background-color: #eeeeee;
  cursor: pointer;
  white-space: nowrap;
}

.usStatus_table__3hxrU td {
  padding: 0.3rem 1.5rem;
  white-space: nowrap;
}

.usStatus_table__3hxrU td:first-child {
  font-weight: bold;
}

.usStatus_table__3hxrU tr:nth-child(2n) {
  background-color: #f5f5f5;
}

.usStatus_table__3hxrU tbody tr:hover {
  background-color: #464646;
  color: white;
}

.usStatus_table__container__1BY2B {
  display: flex;
  flex-direction: column;
  width: 100%;
  align-items: center;
}

.usStatus_active_case__syGFX {
  background-color: var(--red) !important;
  color: white;
}

.usStatus_active_death__3CGoL {
  background-color: var(--gray) !important;
  color: white;
}

.usStatus_active_deathsPerMillion__3KAdW {
  background-color: var(--orange) !important;
  color: white;
}

.usStatus_active_casesPerMillion__UX5Ja {
  background-color: var(--purple) !important;
  color: white;
}

.usStatus_field__select__2drzg {
  display: none;
}

@media (max-width: 1400px) and (min-width: 1130px) {
  .usStatus_table__3hxrU {
    width: 70%;
    max-width: 1000px;
  }

  .usStatus_table__3hxrU th {
    padding: 0.2rem 0.6rem;
    font-size: 1rem;
  }

  .usStatus_table__3hxrU td {
    padding: 0.2rem 0.6rem;
    font-size: 1rem;
  }
}
@media (max-width: 1130px) and (min-width: 950px) {
  .usStatus_table__3hxrU {
    width: 70%;
    max-width: 700px;
  }

  .usStatus_table__3hxrU th {
    padding: 0.9rem 0.3rem;
    font-size: 0.8rem;
  }

  .usStatus_table__3hxrU td {
    padding: 0.1rem 0.3rem;
    font-size: 0.8rem;
  }

  .usStatus_table__header__1CxaL {
    margin-top: 1rem;
    height: 15vh;
    width: auto;
  }
}
@media (max-width: 950px) and (min-width: 500px) {
  .usStatus_table__3hxrU {
    width: 90%;
    max-width: 700px;
  }

  .usStatus_table__3hxrU th {
    padding: 0.6rem 0.3rem;
    font-size: 0.8rem;
  }

  .usStatus_table__3hxrU td {
    padding: 0.1rem 0.3rem;
    font-size: 0.8rem;
  }

  .usStatus_table__header__1CxaL {
    margin-top: 1rem;
    height: 10vh;
    width: auto;
  }

  .usStatus_table__info__3FZRT {
    padding: 0.8rem 0;
    font-size: 0.8rem;
  }
}
@media (max-width: 800px) {
  .usStatus_table__3hxrU th {
    display: none;
  }

  .usStatus_table__3hxrU td {
    display: none;
  }

  .usStatus_field__select__2drzg {
    display: block;
    width: 50%;
    border-radius: 10px;
    outline: none;
    padding: 0.2rem 0;
    margin-top: 0.5rem;
    cursor: pointer;
  }

  table .usStatus_state__PsUFf {
    display: table-cell;
  }

  table th.usStatus_active_case__syGFX {
    display: table-cell;
  }
  table td.usStatus_active_row__16OpX {
    display: table-cell;
  }

  table th.usStatus_active_death__3CGoL {
    display: table-cell;
  }
  table th.usStatus_active_casesPerMillion__UX5Ja {
    display: table-cell;
  }
  table th.usStatus_active_deathsPerMillion__3KAdW {
    display: table-cell;
  }
}
@media (max-width: 500px) {
  .usStatus_table__3hxrU {
    width: 90%;
    max-width: 450px;
  }

  .usStatus_table__3hxrU th {
    padding: 0.4rem 0.2rem;
    font-size: 0.7rem;
  }

  .usStatus_table__3hxrU td {
    padding: 0.1rem 0.3rem;
    font-size: 0.7rem;
  }

  .usStatus_table__info__3FZRT {
    padding: 0.6rem 0;
    font-size: 0.7rem;
  }
}

