.article__main__container {
  display: flex;
  flex-direction: column;
  padding: 3.4rem 0;
  width: 100vw;
}

.article__container {
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  padding-top: 1rem;
  background: url(../../assets/img/pawel-czerwinski-WteXlFwm0jY-unsplash.jpg)
    no-repeat center center fixed;
  /* background-size: cover; */
}

.article__main__header {
  max-width: 70%;
  padding: 1rem;
  font-family: var(--headingFont);
  font-size: 2rem;
  align-self: center;
}

@media (max-width: 600px) {
  .article__main__header {
    font-size: 1.25rem;
  }
}
