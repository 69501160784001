.marker {
  border-radius: 50%;
  display: flex;
  justify-content: center;
  align-items: center;
  font-weight: bold;
  font-size: 0.8rem;
}

.inverted {
  background-color: var(--blue) !important;
}

.red {
  background-color: var(--red);
  border: 1px solid white;
  color: white;
}

.gray {
  background-color: var(--gray);
  border: 1px solid white;
  color: white;
}

.green {
  background-color: var(--green);
  border: 1px solid white;
  color: white;
}

.yellow {
  background-color: var(--yellow);
  border: 1px solid white;
  color: white;
}

.orange {
  background-color: var(--orange);
  border: 1px solid white;
  color: white;
}

.purple {
  background-color: var(--purple);
  border: 1px solid white;
  color: white;
}
